import React from 'react';
import ReactDOM from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import PretendardVariable from "./assets/font/PretendardVariable.woff2";
import GGCJ from "./assets/font/GGCJ_Bold.ttf";
import NanumBrush from "./assets/font/NanumBrush.ttf";
import App from './App';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'PretendardVariable';
    src: url(${PretendardVariable});
  }
  @font-face {
    font-family: 'GGCJ';
    src: url(${GGCJ});
  }
  @font-face {
    font-family: 'NanumBrush';
    src: url(${NanumBrush});
  }
  html{
    scroll-behavior: smooth;
    font-family: "PretendardVariable";
  }
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    & .ql-align-center{
      text-align: center;
    }
    & .ql-align-right{
      text-align: right;
    }
    & .ql-align-justify{
      text-align: justify;
    }
  }
  a{
    font-family: "PretendardVariable";
    color: black;
    text-decoration: none;
    @font-face {
    font-family: 'PretendardVariable';
    src: url(${PretendardVariable});
  }
  }
  button{
    font-family: "PretendardVariable";
    border: none;
    background-color: transparent;
    cursor: pointer !important;
  }
`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <GlobalStyle />
    <App />
  </BrowserRouter>
);
