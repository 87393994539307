import styled from "styled-components";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Container = styled.main`
  padding: 10% 0;
  min-height: 70vh;
  width: 80%;
  display: flex;
  column-gap: 5vw;
  align-items: center;
  justify-content: center;
  font-size: 2vw;
  background-color: rgba(200, 200, 200, 1);
  &>button{
    padding: 3% 5%;
    border-radius: 14px;
    color: white;
    font-size: 2vw;
    font-weight: 700;
    background-color: rgba(200, 100, 25, 1);
  }
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2vw;
  justify-content: space-evenly;
`
const InputContainer = styled.div`
  display: flex;
  column-gap: 3vw;
  align-items: center;
  justify-content: space-between;
  &>label{
    align-items: center;
  }
  &>input{
    min-height: 3vh;
    width: 14vw;
  }
`

function Login(){
  const [isInputValue, setIsInputValue] = useState({
    id: "",
    password: "",
  })
  const navigation = useNavigate();
  function send(){
    axios.post(process.env.REACT_APP_API_URL+"admin/login",isInputValue)
    .then(res=>{
      localStorage.setItem("theFirstEduLogin", process.env.REACT_APP_LOGIN_HASH)
      navigation("/admin")
    })
    .catch(err=>{
      alert("로그인을 실패하였습니다.")
    })
  }

  return(
    <Container>
      <Box>
        <InputContainer>
          <label htmlFor="id">ID</label>
          <input
            id="id"
            type="text"
            value={isInputValue.id}
            onChange={e=>setIsInputValue({...isInputValue, id: e.target.value})}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="password">PASSWORD</label>
          <input
            id="password"
            type="password"
            value={isInputValue.password}
            onChange={e=>setIsInputValue({...isInputValue, password: e.target.value})}
          />
        </InputContainer>
      </Box>
        <button onClick={send}>전송</button>
    </Container>
  )
}
export default Login;