
import styled from "styled-components"

const Container = styled.article`
  z-index: 99999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`
const Box = styled.section`
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border-radius: 18px;
  background-color: white;
  &>h1{
    padding: 3%;
  }
  & video,img{
    height: 30rem;
    width: 30rem;
  }
`
const ContentBox = styled.div`
  flex: 1;
  width: ${props=>props.className};
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  white-space: wrap;
  overflow-y: scroll;
`
const FooterBox = styled.span`
  padding: 2vw;
  display: flex;
  justify-content: space-around;
`
const Footer1 = styled.button`
  padding: 1vw;
  border-radius: 18px;
  color: white;
  background-color: ${props=>props.children};
`
const Footer2 = styled.button`
  padding: 1vw;
  border-radius: 18px;
  color: black;
  background-color: #F5B919;
`

function Modal({setOpenModal,title,content,footer1Color,footer1,widthPer}){
  return(
    <Container onClick={e=>setOpenModal(false)}>
      <Box onClick={e=>e.stopPropagation()}>
        <h1>{title}</h1>
        <ContentBox className={widthPer}>{content}</ContentBox>
        <FooterBox>
          {footer1? <Footer1 className={footer1Color}>{footer1}</Footer1> : null}
          <Footer2 onClick={()=>setOpenModal(false)}>닫기</Footer2>
        </FooterBox>
      </Box>
    </Container>
  )
}
export default Modal;