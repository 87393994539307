import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import PageNation from "../components/PageNation";

import { ReactComponent as IconSvg } from "../assets/storyPageImg/message_icon.svg";

const Container = styled.main`
  width: 1299px;
  padding: 126px 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 96px;
  @media (max-width: 1024px) {
    align-items: center;
    width: 100%;
    padding: 35px 46px 0;
    row-gap: 40px;
  }
  @media (max-width: 730px) {
    padding: 35px 0 0;
    row-gap: 5.4vw;
  }
`
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  &>:nth-child(2)>p{
    font-size: 37px;
    font-weight: 400;
  }
  @media (max-width: 1024px) {
    width: 666px;
    row-gap: 0;
    &>:nth-child(2)>p{
      margin-bottom: 40px;
      font-size: 40px;
      letter-spacing: -.5px;
    }
    &>:nth-child(2)>:first-child{
      display: none;
    }
    &>:nth-child(2)>:last-child{
      display: flex;
    }
    &>:nth-child(3){
      margin-bottom: 95px;
      white-space: pre;
      font-size: 28px;
      letter-spacing: -.5px;
      line-height: 45px;
    }
  }
  @media (max-width: 730px) {
    width: 91vw;
    &>:nth-child(2)>p{
      margin-bottom: 5.4vw;
      font-size: 5.4vw;
    }
    &>:nth-child(3){
      margin-bottom: 13vw;
      font-size: 3.8vw;
      line-height: 5vw;
    }
  }
`
const Title = styled.h1`
  display: flex;
  align-items: center;
  column-gap: 13px;
  font-size: 47px;
  font-weight: 600;
  letter-spacing: -.5px;
  &>svg{height: 75px; width: 75px;}
  @media (max-width: 1024px) {
    margin-bottom: 17px;
    &>span{
      font-size: 57px;
      letter-spacing: 0px;
    }
  }
  @media (max-width: 730px) {
    column-gap: 1.8vw;
    &>svg{height: 10.3vw; width: 10.3vw;}
    &>span{
      font-size: 7.8vw;
    }
  }
`
const ListContainer = styled.article`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  &>a{border-radius: 20px;}
  &>:nth-child(7n+1){background-color: rgb(255, 255, 255);}
  &>:nth-child(7n+2){background-color: rgb(192, 224, 249);}
  &>:nth-child(7n+3){background-color: rgb(254, 207, 93);}
  &>:nth-child(7n+4){background-color: rgb(254, 237, 221);}
  &>:nth-child(7n+5){background-color: rgb(255, 255, 255);}
  &>:nth-child(7n+6){background-color: rgb(192, 224, 249);}
  &>:nth-child(7n+7){background-color: rgb(242, 224, 202);}
  @media (max-width: 1024px) {
    width: 666px;
    gap: 90px;
  }
  @media (max-width: 730px) {
    width: 91vw;
    gap: 12vw;
  }
`
const StoryMedit = styled.section`
  padding: 30px;
  height: 537px;
  width: 416px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  border-radius: 20px;
  box-shadow: 1px 1px 37px .1px rgba(0,0,0,.1);
  &>h4{
    color: #6a6a6a;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -.5px;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &>p{
    display: flex;
    display: -webkit-box;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -.5px;
    line-height: 40px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
  }
  @media (max-width: 1024px) {
    padding: 52px 45px;
    height: 910px;
    width: 666px;
    row-gap: 45px;
    &>h4{
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 0;
    }
    &>p{
      font-size: 50px;
      letter-spacing: -.5px;
      line-height: 65px;
    }
  }
  @media (max-width: 730px) {
    padding: 7.1vw 6.1vw;
    row-gap: 6vw;
    height: 124.6vw;
    width: 91vw;
    &>h4{
      font-size: 3.4vw;
    }
    &>p{
      font-size: 6.8vw;
      line-height: 8.7vw;
    }
  }
`
const PageNationContainer = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    margin: 110px 0 130px;
  }
`

const RecruitContainer = styled.article`
  width: 1299px;
  border-top: solid 3px black;
  border-bottom: solid 3px black;
`
const RecruitTitleContainer = styled.section`
  padding-top: 3.7vh;
  padding-bottom: 3.7vh;
  display: flex;
  font-size: 1.4vw;
  font-weight: 700;
  border-bottom: solid 1px gray;
  text-align: center;
  &>:nth-child(1),:nth-child(3),:nth-child(4){
    width: 18vw;
  }
  &>:nth-child(2){
    width: 100%;
  }
`
const RecruitListContainer = styled.ul`
  list-style: none;
`
const RecruitList = styled.li`
  padding-top: 3.7vh;
  padding-bottom: 3.7vh;
  display: flex;
  font-size: 1vw;
  border-bottom: solid 1px gray;
  text-align: center;
  &>:nth-child(1),:nth-child(3),:nth-child(4){
    width: 18vw;
  }
  &>:nth-child(2){
    width: 100%;
  }
`

const recruits = [
  {no: 167, title: "주간 요양보호사 구인", company: "백마효요양원", date: "채용시까지",
    content: `/케어포프로그램 사용자 우대
/어르신케어 및 배식, 실내외 청소
/경력1년이상
/월급 210만
/근무시간: 09:00-18:00
/2개월근로계약 후 상용직전환검토
/백마역인근
/중식비포함`
  },
  {no: 166, title: "요양보호사 구인합니다.", company: "가족사랑복지센터", date: "2025-01-25",
    content: `- 김포시 월곶면  군하리  재가요양보호사 구인합니다.
- 일상생활 지원서비스
- 근무시간: 09:00~12:00 주5일근무
- 급      여: 시급 12,500(기본 시급 9,860원+주휴수당+기타수당) +차량유지비 지원
- 자차 가능자 

* 실제 서비스 제공 기간에 따라 임금을 계산하여 지급하는 형태로 채용 시 근로자마다 주 소정 근로 시간이나 임금, 근무(예정)지 등에 차이가 있을 수 있음 *`
  },
  {no: 165, title: "[김포 감정동 북변동] 3등급 할머니 방문요양보호사 모집 합니다.", company: "예은방문요양센터", date: "채용시까지",
    content: `- 3등급  어르신

- 시간 :  월~토  / 09-12시 또는 10-13시 / 주6회

- 식사지원, 말벗, 가사지원, 신체활동 지원, 병원동행 등 

- 북변동  북변건영아파트 

- 시급 9860 + 수당포함 = 12500원 

- 문의 : 031-989-0690`
  },
  {no: 164, title: "장기동 재가요양보호사 모집", company: "정다운재가노인복지센터", date: "2025-02-12",
    content: `- 대상자: 5등급 할머님
- 담당업무: 가사, 일상생생활 함께하기 위주로 하기
- 근무시간: 09:00~12:00(시간 조정 가능)/ 주 5일
- 급     여: 시급 13,000원(기본시급 9,860원+주휴수당+기타수당)
*** 기저귀 케어 X

* 실제 서비스 제공 기간에 따라 임금을 계산하여 지급하는 형태로 채용 시 근로자마다 주 소정 근로 시간이나 임금,근무(예정)지 등에 차이가 있을 수 있음."`
  },
]

function Story() {
  const [isArticleList, setIsArticleList] = useState([])
  const [isPageNation,setIsPageNation] = useState(1);
  useEffect(()=>{
    window.scrollTo(0,0)
    axios.get(process.env.REACT_APP_API_URL+"research/category/story")
    .then(res=>setIsArticleList(res.data))
    .catch(err=>console.log(err))
  },[])

  return (
    <Container>
      <TitleBox>
        <Title><IconSvg /><span>채용정보</span></Title>
        {/* <div><p>퍼스트에듀와 공동체와의 톡톡</p></div> */}
        <div><p>취약계층 구직관련 업종정보</p></div>
      </TitleBox>
      {/* <ListContainer>
        {isArticleList.slice((isPageNation-1)*9,(isPageNation-1)*9+9).map(article=>(
          <Link to={"/article/"+article.researchId}>
            <StoryMedit>
              <h4>{article.title}</h4>
              <p>{article.subResearchResponseDtos[0].subContent}</p>
            </StoryMedit>
          </Link>
        ))}
      </ListContainer> */}
      <RecruitContainer>
        <RecruitTitleContainer>
          <span>번호</span>
          <span>제목</span>
          <span>업체명</span>
          <span>마감일</span>
        </RecruitTitleContainer>
        <RecruitListContainer>
          {recruits.map(recruit=>(
            <Link to={"/recruit/"+recruit.no}>
              <RecruitList>
                <span>{recruit.no}</span>
                <span>{recruit.title}</span>
                <span>{recruit.company}</span>
                <span>{recruit.date}</span>
              </RecruitList>
            </Link>
          ))}
        </RecruitListContainer>
      </RecruitContainer>
      <PageNationContainer>
        <PageNation isArticleList={recruits} isPageNation={isPageNation} setIsPageNation={setIsPageNation} />
      </PageNationContainer>
    </Container>
  );
}

export default Story;
