import styled from "styled-components";

import LeftImg from "../assets/left.png";
import RightImg from "../assets/right.png";

const Container = styled.div`
  height: 10.24vw;
  display: flex;
  justify-content: center;
`
const Box = styled.div`
  width: 88.78vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const PageNumber = styled.button`
  display: ${props=>props.thisPage>props.lastPage? "none" : "flex"};
  color: ${props=>props.thisPage==props.className? "black" : "#6a6a6a"};
  font-weight: ${props=>props.thisPage==props.className? 700 : 400};
  @media (max-width: 1024px) {
    font-size: 3.9vw;
  }
`
const UpDownButton = styled.img`
  cursor: pointer;
  @media (max-width: 1024px) {
    height: 10.244vw;
    width: 10.244vw;
  }
`

function PageNation({isArticleList,isPageNation,setIsPageNation}){
  const lastPage = Math.ceil(isArticleList.length/9);
  function handleNumberClick(e){
    if(e.target.innerText<=lastPage){
      setIsPageNation(Number(e.target.innerText))
    }
  }
  return(
    <Container>
      <Box>
        <UpDownButton
          src={LeftImg}
          onClick={()=>isPageNation-1<1? setIsPageNation(1) : setIsPageNation(isPageNation-1)}
        />
        <PageNumber
          thisPage={lastPage<6? 1 : isPageNation}
          lastPage={lastPage}
          className={isPageNation}
          onClick={handleNumberClick}
        >
          {lastPage<6? 1 : isPageNation}
        </PageNumber>
        <PageNumber
          thisPage={lastPage<6? 2 : isPageNation+1}
          lastPage={lastPage}
          className={isPageNation}
          onClick={handleNumberClick}
        >
          {lastPage<6? 2 : isPageNation+1}
        </PageNumber>
        <PageNumber
          thisPage={lastPage<6? 3 : isPageNation+2}
          lastPage={lastPage}
          className={isPageNation}
          onClick={handleNumberClick}
        >
          {lastPage<6? 3 : isPageNation+2}
        </PageNumber>
        <PageNumber
          thisPage={lastPage<6? 4 : isPageNation+3}
          lastPage={lastPage}
          className={isPageNation}
          onClick={handleNumberClick}
        >
          {lastPage<6? 4 : isPageNation+3}
        </PageNumber>
        <PageNumber
          thisPage={lastPage<6? 5 : isPageNation+4}
          lastPage={lastPage}
          className={isPageNation}
          onClick={handleNumberClick}
        >
          {lastPage<6? 5 : isPageNation+4}
        </PageNumber>
        <UpDownButton
          src={RightImg}
          onClick={()=>isPageNation+1<lastPage? setIsPageNation(isPageNation+1) : setIsPageNation(lastPage)}
        />
      </Box>
    </Container>
  )
}
export default PageNation;