import styled from "styled-components";
import UrlShare from "../assets/share/urlShare.png";
import { useState } from "react";

const Container = styled.article`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  background-color: rgba(140, 140, 140, .8);
  cursor: auto;
`
const Box = styled.section`
  height: 33%;
  width: 80%;
  display: flex;
  flex-direction: column;
  row-gap: 10vw;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background-color: rgb(255, 255, 255);
`
const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &>input{
    max-width: 80%;
  }
  /* &>:last-child{
    height: 2.5vw;
    width: 2.5vw;
    background-image: url(${UrlShare});
    background-position: center;
    background-repeat: no-repeat;
    background-clip: border-box;
  } */
  @media (max-width: 1024px) {
    &>input{
      font-size: 2.4vw;
    }
  }
`
function Share({setOpenShare, researchId}){
  const [isLink, setIsLink] = useState("")
  return(
    <Container onClick={e=>{e.preventDefault(); setOpenShare(false);}}>
      <Box onClick={e=>{e.preventDefault(); e.stopPropagation();}}>
        <ButtonBox>
          <input
            type="text" readOnly={true} autoFocus={true}
            value={window.location.origin+"/article/"+researchId}
            onFocus={e=>{
              e.target.select()
              setIsLink(e.target.value)
            }}
          />
          {/* <button
            onClick={()=>{
              navigator.clipboard.writeText(isLink)
              document.execCommand("copy",true,isLink)
              alert("링크가 복사되었습니다.")
            }}
          /> */}
        </ButtonBox>
      </Box>
    </Container>
  )
}
export default Share;