import styled from "styled-components";
import axios from "axios";
import { useEffect, useState } from "react";
import BackgroundImg from "../assets/brandingPageImg/branding_banner.png";
import BackgroundImgM from "../assets/brandingPageImg/branding_banner_M.png";
import { ReactComponent as IconSvg } from "../assets/brandingPageImg/branding_icon.svg";
import imgSet from "../assets/brandingPageImg/branding_imgSet.png";
import imgSetM from "../assets/brandingPageImg/branding_imgSet_M.png";
import Article from "./article/Article";
import PageNation from "../components/PageNation";

const Container = styled.main`
  width: 1299px;
  padding: 126px 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 108px;
  @media (max-width: 1024px) {
    align-items: center;
    width: 100%;
    padding: 35px 46px 0;
    row-gap: 40px;
  }
  @media (max-width: 730px) {
    padding: 35px 0 0;
    row-gap: 5.4vw;
  }
`
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  &>:nth-child(2)>p{
    font-size: 37px;
    font-weight: 400;
  }
  &>:nth-child(3){
    margin-bottom: 34px;
    color: #504f4f;
    font-size: 22px;
    font-weight: 450;
    letter-spacing: -.5px;
  }
  @media (max-width: 1024px) {
    row-gap: 0;
    &>:nth-child(2)>p{
      margin-bottom: 40px;
      font-size: 40px;
      letter-spacing: -.5px;
    }
    &>:nth-child(2)>:first-child{
      display: none;
    }
    &>:nth-child(2)>:last-child{
      display: flex;
    }
    &>:nth-child(3){
      margin-bottom: 95px;
      white-space: pre;
      font-size: 28px;
      letter-spacing: -.5px;
      line-height: 45px;
    }
  }
  @media (max-width: 730px) {
    &>:nth-child(2)>p{
      margin-bottom: 5.4vw;
      font-size: 5.4vw;
    }
    &>:nth-child(3){
      margin-bottom: 13vw;
      font-size: 3.8vw;
      line-height: 5vw;
    }
  }
`
const Title = styled.h1`
  display: flex;
  align-items: center;
  column-gap: 13px;
  font-size: 47px;
  font-weight: 600;
  letter-spacing: -.5px;
  &>svg{height: 75px; width: 75px;}
  @media (max-width: 1024px) {
    margin-bottom: 17px;
    &>span{
      font-size: 57px;
      letter-spacing: 0px;
    }
  }
  @media (max-width: 730px) {
    column-gap: 1.8vw;
    &>svg{height: 10.3vw; width: 10.3vw;}
    &>span{
      font-size: 7.8vw;
    }
  }
`
const BannerContainer = styled.div`
  height: 625px;
  width: 1299px;
  background-image: url(${BackgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  background-origin: border-box;
  border-radius: 18px;
  @media (max-width: 1024px) {
    height: 730px;
    width: 666px;
    background-image: url(${BackgroundImgM});
  }
  @media (max-width: 730px) {
    height: 100vw;
    width: 91vw;
  }
`
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 56px;
  @media (max-width: 1024px) {
    width: 666px;
    margin-top: 50px;
    row-gap: 60px;
  }
  @media (max-width: 730px) {
    width: 91vw;
    row-gap: 8vw;
  }
`
const ListTitle = styled.h2`
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -.5px;
  @media (max-width: 1024px) {
    font-size: 45px;
  }
  @media (max-width: 730px) {
    font-size: 31px;
    row-gap: 6vw;
  }
`
const ListBox = styled.article`
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    gap: 90px;
  }
  @media (max-width: 730px) {
    row-gap: 12vw;
  }
`
const ImgSet = styled.article`
  height: 430px;
  width: 1299px;
  display: flex;
  align-items: end;
  background-image: url(${imgSet});
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  background-clip: border-box;
  background-origin: border-box;
  &>section{
    flex: 1;
    padding-bottom: 36px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    text-align: center;
    &>h3{
      font-size: 38px;
      font-weight: 600;
      letter-spacing: -.5px;
    }
    &>p{
      white-space: pre;
      color: #5d5d5d;
      font-size: 21px;
      font-weight: 600;
      letter-spacing: -.5px;
      line-height: 29px;
    }
  }
  @media (max-width: 1024px) {
    height: 2224px;
    width: 666px;
    background-image: url(${imgSetM});
    & *{
      display: none;
    }
  }
  @media (max-width: 730px) {
    height: 304.6vw;
    width: 91vw;
  }
`
const PageNationContainer = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    margin: 110px 0 130px;
  }
`

function Branding() {
  const [isArticleList, setIsArticleList] = useState([]);
  const [isPageNation,setIsPageNation] = useState(1);
  useEffect(()=>{
    window.scrollTo(0,0)
    axios.get(process.env.REACT_APP_API_URL+"research/category/branding")
    .then(res=>{
      setIsArticleList(res.data)
    })
    .catch(err=>{
      alert("데이터를 불러오지 못했습니다.")
    })
  },[])
  return (
    <Container>
      <TitleBox>
        <Title><IconSvg /><span>브랜딩</span></Title>
        <div><p>올바른 시선에서 새로운 시작이 될 수 있도록</p></div>
        <div>퍼스트에듀는 지역 내 공동체와 협력하여 기업에 맞는 {"\n"}비전과 디자인을 제공합니다.</div>
        <BannerContainer />
      </TitleBox>

      <ImgSet>
        <section>
          <h3>기획컨설팅</h3>
          <p>
            사업 행사 기획 / 사업계획서 작성{"\n"}
            기업 및 기관 브랜딩
          </p>
        </section>
        <section>
          <h3>디자인</h3>
          <p>
            로고 / 판촉물 / 굿즈{"\n"}
            분야별 디자인
          </p>
        </section>
        <section>
          <h3>홈페이지</h3>
          <p>
            랜딩 / 반응형 / 쇼핑몰{"\n"}
            맞춤형 홈페이지
          </p>
        </section>
      </ImgSet>

      <ListContainer>
        <ListTitle>브랜딩 포트폴리오</ListTitle>
        <ListBox>
          {isArticleList.slice((isPageNation-1)*9,(isPageNation-1)*9+9).map(post=>(
            <Article category={post.category} post={post} />
          ))}
        </ListBox>
      </ListContainer>
      <PageNationContainer>
        <PageNation isArticleList={isArticleList} isPageNation={isPageNation} setIsPageNation={setIsPageNation} />
      </PageNationContainer>
    </Container>
  );
}
export default Branding;