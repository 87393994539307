import styled from "styled-components";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";

const Container = styled.main`
  padding: 7% 20% 0;
  & *{
    font-size: 2rem;
  }
`
const WriteContainer = styled.div`
  margin: 0 0 14%;
  padding: 3% 5%;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  background-color: rgb(200, 150, 50);
`
const InputContainer = styled.div`
  margin: 0 0 14%;
  display: flex;
  column-gap: 1rem;
  justify-content: center;
  &>div{
    align-self: center;
    border-radius: 18px;
  }
  &>input,select{
    margin-right: 5rem;
    width: 10rem;
    text-align: center;
  }
  &>button{
    padding: 3% 5%;
    border-radius: 18px;
    background-color: rgb(200, 150, 50);
  }
`

function Admin(){
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isModalValue, setIsModalValue] = useState({});
  const [isCategory, setIsCategory] = useState("");
  const [lookUpNumber, setLookUpNumber] = useState();
  const [deleteNumber, setDeleteNumber] = useState();

  useEffect(()=>{
    if(!(localStorage.getItem("theFirstEduLogin")==process.env.REACT_APP_LOGIN_HASH)){
      navigate("/login")
    }
  },[])

  return(
    <Container>

      <WriteContainer>
        <Link to="/write">글 쓰러 가기</Link>
      </WriteContainer>

      <InputContainer>
        <div>조회할 카테고리</div>
        <select name="카테고리" onChange={e=>setIsCategory(e.target.value)}>
          <option value="">전체</option>
          <option value="/category/art">문화예술</option>
          <option value="/category/edu">교육</option>
          <option value="/category/branding">브랜딩</option>
          <option value="/category/story">채용정보</option>
        </select>
        <button
          onClick={()=>{
            axios.get(process.env.REACT_APP_API_URL+"research"+isCategory)
            .then(res=>{
              setOpenModal(true)
              setIsModalValue({
                title: "조회 내용",
                content: res.data.map(el=>(
                  <div>
                    [{el.category}]{el.researchId}: {el.title}
                  </div>
                )),
              })
            })
            .catch(err=>{
              setOpenModal(true)
              setIsModalValue({
                title: "조회 내용",
                content: <div><div>데이터 불러오기를 실패하였습니다.</div>{err.message}</div>,
              })
            })
          }}
        >
          모든 글 조회
        </button>
      </InputContainer>

      <InputContainer>
        <div>조회할 게시글의 ID</div>
        <input
          type="number"
          value={lookUpNumber}
          onChange={e=>setLookUpNumber(e.target.value)}
        />
        <button
          onClick={()=>{
            axios.get(process.env.REACT_APP_API_URL+"research/"+lookUpNumber)
            .then(res=>{
              setOpenModal(true)
              setIsModalValue({
                title: "단일 조회 내용",
                content: <div><div>[{res.data.category}]{res.data.researchId}: {res.data.title}</div><div>{res.data.videoUrl? <video
                  src={res.data.videoUrl}
                  controls={true}
                  controlsList="nodownload"
                /> : null}
                {res.data.imageUrl? <img src={res.data.imageUrl} />: null}
                </div></div>,
              })
            })
            .catch(err=>{
              setOpenModal(true)
              setIsModalValue({
                title: "단일 조회 내용",
                content: <div><div>데이터 불러오기를 실패하였습니다.</div>{err.message}</div>,
              })
            })
          }}
        >
          단일 조회
        </button>
      </InputContainer>

      <InputContainer>
        <div>삭제할 게시글의 ID</div>
        <input
          type="number"
          value={deleteNumber}
          onChange={e=>setDeleteNumber(e.target.value)}
        />
        <button
          onClick={()=>{
            axios.get(process.env.REACT_APP_API_URL+"research/"+deleteNumber)
            .then(res=>{
              setOpenModal(true)
              setIsModalValue({
                title: "단일 삭제 내용",
                content: <div>"{res.data.researchId}: {res.data.title}" 게시글을 정말 삭제하시겠습니까?</div>,
                footer1Color: "red",
                footer1: <button onClick={()=>{
                  axios.delete(process.env.REACT_APP_API_URL+"research/"+deleteNumber)
                  .then(res=>{
                    alert("삭제를 성공하였습니다.")
                  })
                }}>삭제</button>,
              })
            })
            .catch(err=>{
              setOpenModal(true)
              setIsModalValue({
                title: "단일 조회 내용",
                content: <div><div>게시글 삭제를 실패하였습니다.</div>{err.message}</div>,
              })
            })
          }}
        >
          단일 삭제
        </button>
      </InputContainer>

      <InputContainer>
        <button
          onClick={()=>{
            setOpenModal(true)
            setIsModalValue({
              title: "정말 모든 게시물을 삭제하시겠습니까?",
              content: "삭제한 게시물은 복구할 수 없습니다.",
              footer1Color: "red",
              footer1: <button
                onClick={()=>axios.delete(process.env.REACT_APP_API_URL+"research").then(res=>{alert("모든 게시물이 삭제되었습니다."); setOpenModal(false);}).catch(err=>{alert("모든 게시물이 삭제를 실패하였습니다."); setOpenModal(false);})}
              >모두 삭제</button>,
              widthPer: "80%"
            })
          }}
        >
          모든 게시물 삭제
        </button>
      </InputContainer>

      {openModal? <Modal setOpenModal={setOpenModal} title={isModalValue.title} content={isModalValue.content} footer1Color={isModalValue.footer1Color} footer1={isModalValue.footer1} widthPer="90%" /> : null}
    </Container>
  )
}
export default Admin;