import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import useZustand from "./utill/state";

import Header from "./components/layouts/Header";
import Home from "./pages/Home";
import Introduction from "./pages/Introduction";
import Art from "./pages/Art";
import Edu from "./pages/Edu";
import Branding from "./pages/Branding";
import Story from "./pages/Story";
import RecruitPage from "./pages/RecruitPage";

import SearchResult from "./pages/SearchResult";

import Login from "./pages/admin/Login";
import Admin from "./pages/admin/Admin";
import Write from "./pages/admin/Write";

import ArticleDetail from "./pages/article/ArticleDetail";

import Recruit from "./pages/article/Recruit";

import Footer from "./components/layouts/Footer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &>header{
    width: ${props=>props.className>0? "1299px" : "98vw"};
  }
  @media (max-width: 1024px) {
    overflow-x: hidden;
    &>header{
      width: 100%;
    }
    &>main{
      margin-top: 143px;
    }
  }
  @media (max-width: 730px) {
    &>main{
      margin-top: 19.4vw;
    }
  }
`

function App() {
  const isPage = useZustand.usePage().isPage
  return (
    <Container className={String(isPage)}>
      <Header />
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/introduction" Component={Introduction} />
        <Route path="/art" Component={Art} />
        <Route path="/edu" Component={Edu} />
        <Route path="/branding" Component={Branding} />
        {/* <Route path="/story" Component={Story} /> */}
        <Route path="/recruit" Component={RecruitPage} />

        <Route path="/article/:id" Component={ArticleDetail} />

        <Route path="/recruit/:id" Component={Recruit} />

        <Route path="/search/" Component={SearchResult} />
        <Route path="/search/:text" Component={SearchResult} />

        <Route path="/login" Component={Login} />
        <Route path="/admin" Component={Admin} />
        <Route path="/write" Component={Write} />

        <Route path="/write/:id" Component={Write} />

      </Routes>
      <Footer />
    </Container>
  );
}
export default App;