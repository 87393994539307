import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from 'react-quill';


const Container = styled.form`
  width: 100%;
  font-size: 2vw;
  &>p{
    margin: 5% 0;
    white-space: break-spaces;
    text-align: center;
    font-size: 2vw;
    font-weight: 700;
  }
  &>select{
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    font-size: 2vw;
  }
`
const ArticleContainer = styled.article`
  padding: 5%;
  border: solid 1px yellow;
  display: flex;
  flex-direction: column;
  row-gap: 3vw;
  background-color: rgb(200, 200, 200);
`
const InputContainer = styled.section`
  padding: 3%;
  display: flex;
  flex-direction: column;
  row-gap: 2vw;
  background-color: white;
  &>label{
    align-self: center;
    font-weight: 700;
  }
  &>input{
    font-size: 1.4vw;
    font-weight: 700;
  }
  &>div{
    &>div:nth-of-type(2){
      min-height: 30vw;
      &>div{
        min-height: 30vw;
      }
    }
  }
`
const Limit = styled.div`
  color: ${props=>props.className>1500000? "red" : "black"};
`
const SubmitContainer = styled.button`
  z-index: 999;
  position: fixed;
  bottom: 3%;
  right: 7%;
  padding: 1%;
  border-radius: 18px;
  color: white;
  display: flex;
  justify-content: center;
  background-color: rgb(200, 150, 50);
`

function Write(){
  const param = useParams().id;
  const [isInputValue, setIsInputValue] = useState({
    category: "art",
    title: null,
    researchVideo: null,
    researchImage1: null,
    content: null,
    subImageUrl1: null,
    subContent2: null,
    subTitle2: null,
    subImageUrl2: null,
    subTitle1: null,
    subContent1: null,
  })
  console.log(isInputValue)
  const [quillValue, setQuillValue] = useState('');
  const [quillValue2,setQuillValue2] = useState('');
  const [quillValue3,setQuillValue3] = useState('');
  const formats = [
    "link", "image", "video",
    "header",
    "bold", "italic", "underline", "strike",
    "blockquote",
    "list", "bullet", 'indent',
    "color", "background",
    "align",
    // "clean",
    // "font",
    // "size",
    // "indent",
    // "width",
  ]
  const modules = {
    toolbar: {
      container: [
        ["link", "image", "video",],
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, {'indent': '-1'}, {'indent': '+1'}],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        // ["clean"],
      ],
    },
  }

  const navigate = useNavigate();
  useEffect(()=>{
    if(!(localStorage.getItem("theFirstEduLogin")==process.env.REACT_APP_LOGIN_HASH)){
      navigate("/login")
    }
  },[])

  useEffect(()=>{
    if(param){
      axios.get(process.env.REACT_APP_API_URL+"research/"+param)
      .then(res=>{
        console.log(res.data)
        setIsInputValue({
          category: res.data.category,
          title: res.data.title,
          researchVideo: res.data.researchVideo,
          researchImage1: res.data.researchImage1,
          // content: res.data.content,
          subImageUrl1: res.data.subResearchResponseDtos[0].subImageUrl,
          // subContent2: res.data.subResearchResponseDtos[1].subContent,
          // subTitle2: res.data.subResearchResponseDtos[1].subTitle,
          subImageUrl2: res.data.subResearchResponseDtos[1].subImageUrl,
          subTitle1: res.data.subResearchResponseDtos[0].subTitle,
          subContent1: res.data.subResearchResponseDtos[0].subContent,
        })
        setQuillValue(res.data.content)
        setQuillValue2(res.data.subResearchResponseDtos[1].subContent)
        setQuillValue3(res.data.subResearchResponseDtos[1].subTitle)
      })
      .catch(err=>alert("수정한 게시물을 불러오는 것에 실패하였습니다."))
    }
  },[])
  useEffect(()=>{
    setIsInputValue({...isInputValue,content: quillValue})
  },[quillValue])
  useEffect(()=>{
    setIsInputValue({...isInputValue,subContent2: quillValue2})
  },[quillValue2])
  useEffect(()=>{
    setIsInputValue({...isInputValue,subTitle2: quillValue3})
  },[quillValue3])

  function send(e){
    e.preventDefault();
    if(!isInputValue.title){
      alert("글의 제목을 입력해주시기 바랍니다.")
    }
    else if(!isInputValue.content){
      alert("글의 내용을 입력해주시기 바랍니다.")
    }
    else if(isInputValue.category=="branding" && isInputValue.subContent1==null){
      alert("브랜딩 페이지 게시물은 카테고리도 입력하셔야 합니다.")
    }
    else if(isInputValue.category=="story" && isInputValue.subContent1==null){
      alert("채용정보 페이지 게시물은 부제목도 입력하셔야 합니다.")
    }
    else{
      if(param){
        axios.patch(process.env.REACT_APP_API_URL+"research/"+param,{
          category: isInputValue.category, //카테고리
          title: isInputValue.title, //제목
          subContent1: isInputValue.subContent1, //브랜딩카테고리 or 이야기 부제목
          researchVideo: isInputValue.researchVideo, //영상파일
          researchImage1: isInputValue.researchImage1, //대문이미지파일
          content: isInputValue.content, //본문
          researchImage2: isInputValue.subImageUrl1, //서브이미지파일1
          subContent2: isInputValue.subContent2, //서브콘텐츠1
          researchImage3: isInputValue.subImageUrl2, //서브이미지파일2
          subTitle2: isInputValue.subTitle2, //서브콘텐츠2
          subTitle1: isInputValue.subTitle1, //해쉬태그
        },{
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res=>{
          alert("글이 작성되었습니다.")
          navigate("/admin")
        })
        .catch(err=>{
          alert("글 작성에 실패하였습니다.")
        })
      }
      else{
        axios.post(process.env.REACT_APP_API_URL+"research",{
          category: isInputValue.category,
          title: isInputValue.title,
          content: isInputValue.content,
          researchVideo: isInputValue.researchVideo,
          researchImage1: isInputValue.researchImage1,
          subTitle1: isInputValue.subTitle1,
          subContent1: isInputValue.subContent1,
          researchImage2: isInputValue.subImageUrl1,
          subTitle2: isInputValue.subTitle2,
          subContent2: isInputValue.subContent2,
          researchImage3: isInputValue.subImageUrl2,
        },{
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res=>{
          alert("글이 작성되었습니다.")
          navigate("/admin")
        })
        .catch(err=>{
          alert("글 작성에 실패하였습니다.")
        })
      }
    }
  }

  return(
    <Container onSubmit={send}>
      <p>
        * 표시가 되어있는 2개의{"\n"}
        입력란(글 제목, 본문 내용)은{"\n"}
        필수 항목입니다.
      </p>
      <select name="카테고리" onChange={e=>setIsInputValue({...isInputValue, category: e.target.value})}>
        <option value="art">문화예술</option>
        <option value="edu">교육</option>
        <option value="branding">브랜딩</option>
        <option value="story">채용정보</option>
      </select>
      <ArticleContainer>
        <InputContainer>
          <label htmlFor="title">글 제목 *</label>
          <input
            id="title"
            type="text"
            placeholder="글의 제목을 입력해주세요."
            onKeyDown={e=>e.key=="Enter"? e.preventDefault() : null}
            value={isInputValue.title}
            onChange={e=>setIsInputValue({...isInputValue, title: e.target.value})}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="researchVideo">메인 동영상</label>
          <input
            id="researchVideo"
            type="file"
            accept='video/*'
            onChange={e=>setIsInputValue({...isInputValue, researchVideo: e.target.files[0],})}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="researchImage1">대표 이미지</label>
          <input
            id="researchImage1"
            type="file"
            accept='image/*'
            onChange={e=>setIsInputValue({...isInputValue, researchImage1: e.target.files[0],})}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="content">첫번째 입력란 *</label>
          <ReactQuill
            id="content"
            theme="snow"
            value={quillValue}
            onChange={setQuillValue}
            modules={modules}
            formats={formats}
          />
          <Limit className={String(quillValue).length}>{String(quillValue).length.toLocaleString()} / 1,500,000</Limit>
        </InputContainer>
        <InputContainer>
          <label htmlFor="subImageUrl1">서브 이미지 1</label>
          <input
            id="subImageUrl1"
            type="file"
            accept='image/*'
            onChange={e=>setIsInputValue({...isInputValue, subImageUrl1: e.target.files[0],})}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="subContent2">서브 입력란 1</label>
          <ReactQuill
            theme="snow"
            value={quillValue2}
            onChange={setQuillValue2}
            modules={modules}
            formats={formats}
          />
          <Limit className={String(quillValue2).length}>{String(quillValue2).length.toLocaleString()} / 1,500,000</Limit>
        </InputContainer>
        <InputContainer>
          <label htmlFor="subImageUrl2">서브 이미지 2</label>
          <input
            id="subImageUrl2"
            type="file"
            accept='image/*'
            onChange={e=>setIsInputValue({...isInputValue, subImageUrl2: e.target.files[0],})}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="subTitle2">서브 입력란 2</label>
          <ReactQuill
            theme="snow"
            value={quillValue3}
            onChange={setQuillValue3}
            modules={modules}
            formats={formats}
          />
          <Limit className={String(quillValue3).length}>{String(quillValue3).length.toLocaleString()} / 1,500,000</Limit>
        </InputContainer>
        <InputContainer>
          <label htmlFor="subTitle1">해쉬태그</label>
          <input
            id="subTitle1"
            type="text"
            placeholder="공백없이 #태그#해쉬태그"
            onKeyDown={e=>e.key==" "||e.key=="Enter"||e.key==","? e.preventDefault() : null}
            value={isInputValue.subTitle1}
            onChange={e=>setIsInputValue({...isInputValue, subTitle1: e.target.value})}
          />
        </InputContainer>

        {isInputValue.category=="branding"?
          <InputContainer>
            <label htmlFor="subContent1">브랜딩 카테고리 *</label>
            <input
              id="subContent1"
              type="text"
              placeholder="브랜딩 카테고리"
              readOnly={!(isInputValue.category=="branding")}
              onKeyDown={e=>e.key==" "||e.key=="Enter"||e.key==","? e.preventDefault() : null}
              value={isInputValue.subContent1}
              onChange={e=>setIsInputValue({...isInputValue, subContent1: e.target.value})}
            />
          </InputContainer>
        : null }
        {isInputValue.category=="story"?
          <InputContainer>
            <label htmlFor="subContent1">채용정보 부제목 *</label>
            <input
              id="subContent1"
              type="text"
              placeholder="채용정보 부제목"
              readOnly={!(isInputValue.category=="story")}
              onKeyDown={e=>e.key==" "||e.key=="Enter"||e.key==","? e.preventDefault() : null}
              value={isInputValue.subContent1}
              onChange={e=>setIsInputValue({...isInputValue, subContent1: e.target.value})}
            />
          </InputContainer>
        : null}
      </ArticleContainer>

      <SubmitContainer
        type="submit"
      >
        글쓰기
      </SubmitContainer>

    </Container>
  )
}
export default Write;