import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import useZustand from "../utill/state";

import SearchImg from "../assets/search.png";
import { useEffect } from "react";

const Container = styled.article`
  position: absolute;
  height: 40px;
  padding: .1vw 7px .1vw 1.4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #f5b919;
  border-radius: 25px;
  background-color: white;
  pointer-events: all;
  animation: renderSearchBox .8s;
  &>div>a{
    display: flex;
    align-items: center;
  }
  &>div>a>img{
    height: 27px;
    width: 27px;
  }
  @keyframes renderSearchBox {
    0%{opacity: 0%; transform: translateY(150%);}
    100%{opacity: 100%; transform: translateX(0);}
  }
  @media (max-width: 1024px) {
    padding: .1vw .95vw .1vw 1.4vw;
  }
  @media (max-width: 730px) {
    height: 5.2vw;
    padding: .1vw .95vw .1vw 1.4vw;
    &>div>a>img{
      height: 3.5vw;
      width: 3.5vw;
    }
  }
`
const Search = styled.input`
  flex: 1;
  border: none;
  font-size: 20px;
  font-weight: 400;
  background-color: transparent;
  &:focus{
    outline: none;
  }
  &::placeholder{
    color: #8c8c8c
  }
  @media (max-width: 730px) {
    font-size: 2.7vw;
  }
`

function SearchBox({openSearch, setOpenSearch, searchText, setSearchText}){
  const navigate = useNavigate();
  const setIsPage = useZustand.usePage().setIsPage;
  useEffect(()=>{
    setSearchText("")
  },[])
  return(
    <Container>
      <Search type="text" autoFocus={true} placeholder="검색어를 입력하세요." value={searchText} onChange={e=>setSearchText(e.target.value)} onKeyUp={e=>{
          if(e.key=="Enter"){
            setOpenSearch(false);
            setIsPage(6);
            navigate(`/search/${searchText}`)
          }
      }} />
      <div>
        <Link to={"/search/"+searchText} onClick={()=>{
          setOpenSearch(false);
          setIsPage(6);
        }}><img src={SearchImg} /></Link>
      </div>
    </Container>
  )
}
export default SearchBox;