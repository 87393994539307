import styled from "styled-components";

import { ReactComponent as BalloonSvg } from "../assets/introductionPageImg/introduction_icon_balloon.svg";
import { IntroductionImg01, IntroductionImgM01, IntroductionImg02, IntroductionImg03, IntroductionImg04, IntroductionImgM02, IntroductionImgM03, IntroductionImgM04 } from "../assets/introductionPageImg/introduction_ImgList";
import { ReactComponent as PlusSvg } from "../assets/plus.svg";
import { ReactComponent as XSvg } from "../assets/x.svg";
import { ReactComponent as GlobePuzzleSvg } from "../assets/introductionPageImg/introduction_icon_globePuzzle.svg";
import { useState } from "react";

const Container = styled.main`
  width: 1299px;
  padding: 126px 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 252px;
  @media (max-width: 1024px) {
    align-items: center;
    width: 100%;
    padding: 35px 46px 0;
    row-gap: 230px;
  }
  @media (max-width: 730px) {
    padding: 35px 0 0;
    row-gap: 31.6vw;
  }
`
const TitleContainer = styled.article`
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  &>div{
    margin-bottom: 74px;
    font-size: 37px;
    font-weight: 400;
  }
  @media (max-width: 1024px) {
    row-gap: 17px;
    &>div{
      margin-bottom: 78px;
      font-size: 40px;
      letter-spacing: -.5px;
    }
  }
  @media (max-width: 730px) {
    row-gap: 2.5vw;
    &>div{
      margin-bottom: 10.7vw;
      font-size: 5.4vw;
    }
  }
`
const Title = styled.h1`
  display: flex;
  align-items: center;
  column-gap: 13px;
  &>svg{height: 75px; width: 75px;}
  &>span{
    font-size: 47px;
    font-weight: 600;
    letter-spacing: -.5px;
  }
  @media (max-width: 1024px) {
    &>span{
      font-size: 57px;
      letter-spacing: 0;
    }
  }
  @media (max-width: 730px) {
    &>svg{
      height: 10.2vw;
      width: 10.2vw;
    }
    &>span{
      font-size: 7.7vw;
    }
  }
`
const TextInImg = styled.p`
  display: flex;
  flex-direction: column;
  row-gap: 35px;
  justify-content: center;
  align-items: center;
  height: 732px;
  width: 1299px;
  border-radius: 24px;
  color: white;
  background-image: url(${IntroductionImg01});
  &>h3{
    font-size: 23px;
    font-weight: 600;
  }
  &>div{
    white-space: pre;
    text-align: center;
    line-height: 62px;
    font-size: 42px;
    font-weight: 700;
  }
  @media (min-width: 1025px) {
    &>img{
      display: none;
    }
  }
  @media (max-width: 1024px) {
    height: 666px;
    width: 666px;
    background-image: url(${IntroductionImgM01});
    &>h3{
      display: none;
    }
    &>div{
      display: none;
    }
    &>img{
      height: 666px;
      width: 666px;
      border-radius: 24px;
    }
  }
  @media (max-width: 730px) {
    height: 91.1vw;
    width: 91.1vw;
    &>img{
      height: 91.1vw;
      width: 91.1vw;
    }
  }
`
const EduIsSection = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 52px;
  text-align: center;
  &>h1{
    font-size: 43px;
    font-weight: 600;
  }
  &>p{
    white-space: pre;
    font-size: 19px;
    font-weight: 400;
    letter-spacing: -.5px;
    line-height: 32px;
  }
  @media (max-width: 1024px) {
    row-gap: 75px;
    &>h1{
      font-size: 70px;
    }
    &>p{
      font-size: 26px;
      line-height: 52px;
    }
  }
  @media (max-width: 730px) {
    row-gap: 10.3vw;
    &>h1{
      font-size: 9.5vw;
    }
    &>p{
      font-size: 3.8vw;
      line-height: 7.1vw;
    }
  }
`
const TwoBlockArticle = styled.article`
  display: flex;
  flex-direction: column;
  row-gap: 75px;
  align-items: center;
  @media (max-width: 1024px) {
    row-gap: 90px;
    &>.true{
      transform: rotateY(180deg);
    }
  }
  @media (max-width: 730px) {
    width: 91.1vw;
    row-gap: 12.3vw;
  }
`
const TwoBlock = styled.div`
  transition: .8s;
  & *{transition: .8s;}
  @media (max-width: 1024px) {
    position: relative;
    height: 1164px;
    width: 666px;
    transform-style: preserve-3d;
    &:nth-child(odd)>div{
      flex-direction: column-reverse;
    }
    &:nth-child(even)>div{
      flex-direction: column;
    }
  }
  @media (max-width: 730px) {
    height: 156.09vw;
    width: 88.78vw;
  }
`
const Front = styled.div`
  height: 617px;
  width: 1053px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 12px;
  box-shadow: 2px 5px 40px 0px rgba(0, 0, 0, 0.08);
  &>svg{
    height: 617px;
    width: 526px;
  }
  &>svg:nth-child(odd){
    border-radius: 24px 0 0 24px;
  }
  &>svg:nth-child(even){
    border-radius: 0 24px 24px 0;
  }
  @media (min-width: 1025px) {
    &>img{
      display: none;
    }
  }
  @media (max-width: 1024px) {
    position: absolute;
    height: 1164px;
    width: 666px;
    display: flex;
    backface-visibility: hidden;
    &>svg{
      display: none;
    }
    &>img{
      height: 775px;
      width: 666px;
      &>:first-child{width: 666px;}
    }
    &>img:nth-child(odd){
      border-radius: 24px 24px 0 0;
    }
    &>img:nth-child(even){
      border-radius: 24px 24px 0 0;
    }
  }
  @media (max-width: 730px) {
    height: 156.09vw;
    width: 88.78vw;
    &>img{
      height: 106.1vw;
      width: 91.1vw;
    }
  }
`
const TextBlock = styled.span`
  position: relative;
  width: 527px;
  padding: 82px 0 0 73px;
  display: flex;
  flex-direction: column;
  row-gap: 97px;
  &:nth-child(even){
    padding: 82px 0 0 83px;
  }
  @media (max-width: 1024px) {
    height: 389px;
    width: 666px;
    row-gap: 0;
    justify-content: space-between;
    padding: 55px 45px 45px 43px;
    &:nth-child(even){
      padding: 55px 45px 45px 43px;
    }
  }
  @media (max-width: 730px) {
    height: 53.2vw;
    width: 91.1vw;
    padding: 7.5vw 6.1vw 6.1vw 5.8vw;
    &:nth-child(even){
      padding: 7.5vw 6.1vw 6.1vw 5.8vw;
    }
  }
`
const TextBlockTitle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  &>h2{
    white-space: pre;
    font-size: 37px;
    font-weight: 600;
    line-height: 51px;
  }
  &>div{
    color: #6a6a6a;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: .3px;
  }
  @media (max-width: 1024px) {
    &>h2{
      font-size: 54px;
      line-height: 75px;
    }
    &>div{
      display: none;
    }
  }
  @media (max-width: 730px) {
    &>h2{
      font-size: 7.4vw;
      line-height: 10.2vw;
    }
  }
`
const TextBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  &>p{
    white-space: pre;
    line-height: 28px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .2px;
  }
  &>span{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .2px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`
const Back = styled.span`
  position: absolute;
  height: 1164px;
  width: 666px;
  padding: 145px 45px 45px 43px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  box-shadow: 2px 5px 40px 0px rgba(0, 0, 0, 0.08);
  backface-visibility: hidden;
  transform: rotateY(180deg);
  @media (max-width: 1024px) {
    height: 1164px;
    width: 666px;
  }
  @media (max-width: 730px) {
    height: 156.09vw;
    width: 88.78vw;
    padding: 8.73vw 5.8vw;
  }
`
const BackTextContainer = styled.div`
  &>h2{
    margin-bottom: 45px;
    white-space: pre;
    font-size: 58px;
    font-weight: 600;
    letter-spacing: -.5px;
    line-height: 75px;
  }
  &>div{
    margin-bottom: 105px;
    color: #6a6a6a;
    font-size: 29px;
    font-weight: 400;
    letter-spacing: .3px;
  }
  &>p{
    margin-bottom: 64px;
    white-space: pre;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: .2px;
    line-height: 44px;
  }
  &>span{
    color: #6a6a6a;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: .2px;
  }
  @media (max-width: 730px) {
    &>h2{
      margin-bottom: 2.9vw;
      font-size: 7.3vw;
      line-height: 9.74vw;
    }
    &>div{
      margin-bottom: 11.7vw;
      font-size: 3.9vw;
    }
    &>p{
      margin-bottom: 5.85vw;
      font-size: 3.38vw;
      line-height: 5.82vw;
    }
    &>span{
      font-size: 3.41vw;
    }
  }
`
const PlusButtonBox = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    justify-content: end;
    &>button{
      height: 65px;
      width: 65px;
      border-radius: 50%;
      background-color: #eee;
    }
    &>svg{
      height: 65px;
      width: 65px;
      border-radius: 50%;
      background-color: #eee;
    }
  }
  @media (max-width: 730px) {
    &>button{
      height: 8.9vw;
      width: 8.9vw;
    }
    &>svg{
      height: 8.9vw;
      width: 8.9vw;
    }
  }
`
const SubTitleContainer = styled.article`
  display: flex;
  flex-direction: column;
  row-gap: 41px;
  &>h2{
    font-size: 43px;
    font-weight: 600;
  }
  &>div{
    font-size: 23px;
    font-weight: 400;
    letter-spacing: -.5px;
  }
  @media (max-width: 1024px) {
    margin-top: 5px;
    row-gap: 40px;
    &>h2{
      font-weight: 400;
    }
  }
  @media (max-width: 730px) {
    margin-top: .6vw;
    row-gap: 5vw;
    &>h2{
      font-size: 5.9vw;
    }
    &>div{
      font-size: 3.1vw;
    }
  }
`

function Introduction() {
  const [rotateNumber, setRotateNumber] = useState({
    1: false,
    2: false,
    3: false,
  })

  return (
    <Container>

      <TitleContainer>
        <Title><BalloonSvg /><span>문화</span></Title>
        <div>더 나은 세상을 만드는 더퍼스트에듀</div>
        <TextInImg>
          <h3>비전</h3>
          <div>
            교육과 사람이 만드는{"\n"}
            더 나은 세상
          </div>
          <img src={IntroductionImgM01} />
        </TextInImg>
      </TitleContainer>

      <EduIsSection>
        <h1>교육은 해답입니다.</h1>
        <p>
          더퍼스트에듀는 늘 고민합니다.{"\n"}
          세상에는 다양한 사람들이 살고 그들은 너무나도 다릅니다.{"\n"}
          하지만 우리는 함께 살기를 희망하고 그 안에서 답을 찾기 원하죠.{"\n"}
          다른 우리가 함께 살 수 있는 방법{"\n"}
          그것은 바로 교육입니다.
        </p>
      </EduIsSection>

      <TwoBlockArticle>
        <TwoBlock className={rotateNumber[1]}>
          <Front>
            <TextBlock>
              <TextBlockTitle>
                <h2>유연함으로{"\n"}새로움을 창조합니다.</h2>
                <div>Flexible thinking</div>
              </TextBlockTitle>
              <TextBlockContent>
                <p>
                  "사회적기업 상품은 별로잖아"{"\n"}
                  저희는 유연한 조직 형태와 일의 방법으로 새로움을{"\n"}
                  추구합니다. 하나의 프로젝트를 이루어 내기 위해{"\n"}
                  회사 내부에서 해결이 아닌 다양한 공동체와{"\n"}
                  숨은 보석 1인 기업들과 콜라보를 통해{"\n"}
                  새로운 결과물을 제공합니다. 한번도 경험하지 못한{"\n"}
                  무언가를 원한다면 에듀의 문을 두들겨 주세요.
                </p>
                <span>#유연함 #창조</span>
              </TextBlockContent>
              <PlusButtonBox>
                <button
                  onClick={()=>setRotateNumber({...rotateNumber,1: !rotateNumber[1]})}
                ><PlusSvg /></button>
              </PlusButtonBox>
            </TextBlock>
            <IntroductionImg02 /><img src={IntroductionImgM02} />
          </Front>
          <Back>
            <BackTextContainer>
              <h2>유연함으로{"\n"}새로움을 창조합니다.</h2>
              <div>Flexible thinking</div>
              <p>
                "사회적기업 상품은 별로잖아"{"\n"}
                저희는 유연한 조직 형태와 일의 방법으로 새로움을{"\n"}
                추구합니다. 하나의 프로젝트를 이루어 내기 위해{"\n"}
                회사 내부에서 해결이 아닌 다양한 공동체와{"\n"}
                숨은 보석 1인 기업들과 콜라보를 통해{"\n"}
                새로운 결과물을 제공합니다. 한번도 경험하지 못한{"\n"}
                무언가를 원한다면 에듀의 문을 두들겨 주세요.
              </p>
              <span>#유연함 #창조</span>
            </BackTextContainer>
            <PlusButtonBox>
              <button
                onClick={()=>setRotateNumber({...rotateNumber,1: !rotateNumber[1]})}
              ><XSvg /></button>
            </PlusButtonBox>
          </Back>
        </TwoBlock>
        <TwoBlock className={rotateNumber[2]}>
          <Front>
            <IntroductionImg03 /><img src={IntroductionImgM03} />
            <TextBlock>
              <TextBlockTitle>
                <h2>사람들과{"\n"}계속 함께 합니다.</h2>
                <div>Co-work</div>
              </TextBlockTitle>
              <TextBlockContent>
                <p>
                  "함께 일해보지 않겠어요?"{"\n"}
                  저희는 프로젝트를 협력하고 있는{"\n"}
                  다양한 공동체와 함께 해결합니다.{"\n"}
                  새로운 시선, 새로운 욕구, 새로운 콘텐츠를{"\n"}
                  지속적으로 만들어 낼 수 있는 힘은 바로 사람,{"\n"}
                  다양한 사람들과의 협력을 통해{"\n"}
                  짧지만 강한 결과물을 창조합니다.
                </p>
                <span>#공동체 #협동</span>
              </TextBlockContent>
              <PlusButtonBox>
                <button
                  onClick={()=>setRotateNumber({...rotateNumber,2: !rotateNumber[2]})}
                ><PlusSvg /></button>
              </PlusButtonBox>
            </TextBlock>
          </Front>
          <Back>
            <BackTextContainer>
              <h2>사람들과{"\n"}계속 함께 합니다.</h2>
              <div>Co-work</div>
              <p>
                "함께 일해보지 않겠어요?"{"\n"}
                저희는 프로젝트를 협력하고 있는{"\n"}
                다양한 공동체와 함께 해결합니다.{"\n"}
                새로운 시선, 새로운 욕구, 새로운 콘텐츠를{"\n"}
                지속적으로 만들어 낼 수 있는 힘은 바로 사람,{"\n"}
                다양한 사람들과의 협력을 통해{"\n"}
                짧지만 강한 결과물을 창조합니다.
              </p>
              <span>#공동체 #협동</span>
            </BackTextContainer>
            <PlusButtonBox>
              <button
                onClick={()=>setRotateNumber({...rotateNumber,2: !rotateNumber[2]})}
              ><XSvg /></button>
            </PlusButtonBox>
          </Back>
        </TwoBlock>
        <TwoBlock className={rotateNumber[3]}>
          <Front>
            <TextBlock>
              <TextBlockTitle>
                <h2>지속가능한{"\n"}사회를 만들길 희망합니다.</h2>
                <div>Sustainable</div>
              </TextBlockTitle>
              <TextBlockContent>
                <p>
                  "지구는 언제까지 버틸 수 있을까?"{"\n"}
                  가끔 아침의 풍경을 바라보면 탁한 하늘에 자신의{"\n"}
                  휴대폰만 바라보며 바쁘게 이동하는 사람들을 볼 수 있습니다.{"\n"}
                  우리가 오랫동안 행복하게 살아가기 위해서 가장 중요한{"\n"}
                  사회적 가치 실현을 위해 우리의 기쁨을 타인과 함께{"\n"}
                  나누겠습니다.
                </p>
                <span>#사회적가치 #사회적기업 #지속가능</span>
              </TextBlockContent>
              <PlusButtonBox>
                <button
                  onClick={()=>setRotateNumber({...rotateNumber,3: !rotateNumber[3]})}
                ><PlusSvg /></button>
              </PlusButtonBox>
            </TextBlock>
            <IntroductionImg04 /><img src={IntroductionImgM04} />
          </Front>
          <Back>
            <BackTextContainer>
              <h2>지속가능한{"\n"}사회를 만들길 희망합니다.</h2>
              <div>Sustainable</div>
              <p>
                "지구는 언제까지 버틸 수 있을까?"{"\n"}
                가끔 아침의 풍경을 바라보면 탁한 하늘에 자신의{"\n"}
                휴대폰만 바라보며 바쁘게 이동하는 {"\n"}
                사람들을 볼 수 있습니다.{"\n"}
                우리가 오랫동안 행복하게 살아가기 위해서{"\n"}
                가장 중요한 사회적 가치 실현을 위해 우리의 기쁨을{"\n"}
                타인과 함께 나누겠습니다.
              </p>
              <span>#사회적가치 #사회적기업 #지속가능</span>
            </BackTextContainer>
            <PlusButtonBox>
              <button
                onClick={()=>setRotateNumber({...rotateNumber,3: !rotateNumber[3]})}
              ><XSvg /></button>
            </PlusButtonBox>
          </Back>
        </TwoBlock>
      </TwoBlockArticle>

      <TitleContainer>
        <Title><GlobePuzzleSvg /><span>공동체</span></Title>
        <div>더퍼스트에듀가 만든 더 나은 세상</div>
        <SubTitleContainer>
          <h2>일상의 가치를 위한 다양한 사람들</h2>
          <div>더퍼스트에듀는 전문성 있는 공동체 활동가들과 협력을 통해 문제를 해결합니다.</div>
        </SubTitleContainer>
      </TitleContainer>

    </Container>
  );
}
export default Introduction;