
import styled from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import useZustand from "../../utill/state";

import Nav from "../Nav";
import { ReactComponent as Horizon } from "../../assets/logo/horizon.svg";
import SearchBox from "../SearchBox";
import SearchXPng from "../../assets/searchX.png";
import { ReactComponent as SearchSvg } from "../../assets/search.svg";
import SearchImg from "../../assets/search.png";
import { ReactComponent as HambergerMenu } from "../../assets/hambergerMenu.svg";

const Container = styled.header`
  position: relative;
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  &>span{
    display: flex;
    align-items: center;
  }
  &>span>a{
    display: flex;
    align-items: center;
  }
  &>span>a>svg{
    height: 32px;
    width: 100%;
    fill: #F5B919;
  }
  &>span>a>svg>g{
    height: 32px;
    width: 128px;
  }
  @media (max-width: 1024px) {
    z-index: 9999;
    position: fixed;
    height: 143px;
    padding: 35px 30px;
    &>span>a>svg{
      height: 49px;
    }
    &>span>a>svg>g{
      height: 49px;
      width: 199px;
    }
  }
  @media (max-width: 730px) {
    height: 19.4vw;
    padding: 3.3vw 4vw;
    &>span>a>svg{
      height: 6.5vw;
    }
    &>span>a>svg>g{
      height: 6.5vw;
    }
  }
`
const NavContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  @media (max-width: 1024px) {
    &>nav{
      display: none;
    }
  }
`
const RightContainer = styled.div`
  display: flex;
  column-gap: 5vw;
  align-items: center;
`
const SearchContainer = styled.span`
  display: flex;
  justify-content: right;
  pointer-events: none;
  cursor: pointer;
  & *{pointer-events: all;}
  &>img{
    height: 24px;
    width: 23px;
    fill: black;
  }
  @media (max-width: 1024px) {
    &>img{
      height: 40px;
      width: 40px;
    }
  }
  @media (max-width: 730px) {
    &>img{
      height: 5.4vw;
      width: 5.4vw;
    }
  }
`
const HambergerContainer = styled.button`
  z-index: 9;
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    &>svg{
      height: 49px;
      width: 49px;
    }
  }
  @media (max-width: 730px) {
    &>svg{
      height: 6.8vw;
      width: 6.8vw;
    }
  }
`
const MobileNavBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media (min-width: 1025px) {
    &>nav{
      display: none;
    }
  }
`

function Header() {
  const {isPage,setIsPage} = useZustand.usePage()
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  return (
    <Container className={isNavOpen}>
      <span><Link to="" onClick={()=>setIsPage(0)}><Horizon /></Link></span>
      <NavContainer>
        {openSearch? <SearchBox openSearch={openSearch} setOpenSearch={setOpenSearch} searchText={searchText} setSearchText={setSearchText} /> : <Nav />}
      </NavContainer>
      <RightContainer>
        <SearchContainer>
          {openSearch
          ? <img src={SearchXPng} onClick={()=>setOpenSearch(false)} />
          : <img src={SearchImg} onClick={()=>{
              setOpenSearch(true)
            }} />
          }
        </SearchContainer>
        <HambergerContainer>
          <HambergerMenu onClick={()=>setIsNavOpen(!isNavOpen)} />
        </HambergerContainer>
      </RightContainer>
      {isNavOpen
        ? <MobileNavBackdrop onClick={()=>setIsNavOpen(false)} ><Nav /></MobileNavBackdrop>
        : null }
    </Container>
  );
}

export default Header;