import styled from "styled-components";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BackgroundImg from "../assets/artPageImg/art_banner.png";
import BackgroundImgM from "../assets/artPageImg/art_banner_M.png";
import { ReactComponent as IconSvg } from "../assets/artPageImg/art_icon.svg";
import Article from "./article/Article";
import PageNation from "../components/PageNation";

const Container = styled.main`
  width: 1299px;
  padding: 126px 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 108px;
  @media (max-width: 1024px) {
    align-items: center;
    width: 100%;
    padding: 35px 46px 0;
    row-gap: 90px;
  }
  @media (max-width: 730px) {
    padding: 35px 0 0;
    row-gap: 31.6vw;
  }
`
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  &>:nth-child(2)>p{
    font-size: 37px;
    font-weight: 400;
  }
  &>:nth-child(2)>:last-child{
    display: none;
  }
  &>:nth-child(3){
    margin-bottom: 34px;
    color: #504f4f;
    font-size: 22px;
    font-weight: 450;
    letter-spacing: -.5px;
  }
  @media (max-width: 1024px) {
    row-gap: 0;
    &>:nth-child(2)>p{
      margin-bottom: 40px;
      font-size: 40px;
      letter-spacing: -.5px;
    }
    &>:nth-child(2)>:first-child{
      display: none;
    }
    &>:nth-child(2)>:last-child{
      display: flex;
    }
    &>:nth-child(3){
      margin-bottom: 95px;
      white-space: pre;
      font-size: 28px;
      letter-spacing: -.5px;
      line-height: 45px;
    }
  }
  @media (max-width: 730px) {
    width: 91vw;
    &>:nth-child(2)>p{
      margin-bottom: 5.4vw;
      font-size: 5.4vw;
    }
    &>:nth-child(3){
      margin-bottom: 13vw;
      font-size: 3.8vw;
      line-height: 5vw;
    }
  }
`
const Title = styled.h1`
  display: flex;
  align-items: center;
  column-gap: 13px;
  &>svg{height: 75px; width: 75px;}
  &>span{
    font-size: 47px;
    font-weight: 600;
    letter-spacing: -.5px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 17px;
    &>span{
      font-size: 57px;
      letter-spacing: 0px;
    }
  }
  @media (max-width: 730px) {
    column-gap: 1.8vw;
    &>svg{height: 10.3vw; width: 10.3vw;}
    &>span{
      font-size: 7.8vw;
    }
  }
`
const BannerContainer = styled.div`
  height: 625px;
  width: 1299px;
  padding: 85px 85px 0 0;
  display: flex;
  justify-content: right;
  background-image: url(${BackgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  background-origin: border-box;
  border-radius: 18px;
  @media (max-width: 1024px) {
    height: 730px;
    width: 666px;
    padding: 0 50px 45px 0;
    align-items: end;
    background-image: url(${BackgroundImgM});
  }
  @media (max-width: 730px) {
    height: 94vw;
    width: 91vw;
    padding: 0 6.7vw 6vw 0;
  }
`
const ImgTextContainer = styled.article`
  display: flex;
  flex-direction: column;
  color: white;
  &>:nth-child(1){
    font-size: 25px;
    font-weight: 500;
    letter-spacing: -.5px;
  }
  &>:nth-child(2){
    margin: 12px 0 20px;
    border-top: solid .7px #ffe4c4;
  }
  &>:nth-child(3){
    font-size: 31px;
    font-weight: 500;
    letter-spacing: -.5px;
  }
  &>:nth-child(4){
    margin-top: 12px;
    font-size: 47px;
    letter-spacing: -.2px;
    font-family: 'GGCJ';
  }
  &>:nth-child(5){
    margin-top: 20px;
    white-space: pre;
    line-height: 24px;
    color: black;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -.5px;
  }
  &>a>button{
    height: 45px;
    width: 205px;
    margin-top: 22px;
    border-radius: 50px;
    text-align: center;
    color: white;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: -.2px;
    background-color: rgb(254,132,100);
  }
  @media (max-width: 1024px) {
    display: none;
  }
`
const ButtonInImg = styled.button`
  display: none;
  @media (max-width: 1024px) {
    height: 60px;
    width: 275px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 23px;
    font-weight: 500;
    letter-spacing: -.2px;
    background-color: rgb(254,132,100);
  }
  @media (max-width: 730px) {
    height: 45px;
    width: 205px;
    border-radius: 50px;
    font-size: 17px;
  }
`
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 56px;
  @media (max-width: 1024px) {
    width: 666px;
    margin-top: 35px;
    row-gap: 60px;
  }
  @media (max-width: 730px) {
    width: 91vw;
    row-gap: 8vw;
  }
`
const ListTitle = styled.h2`
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -.5px;
  @media (max-width: 1024px) {
    font-size: 45px;
  }
  @media (max-width: 730px) {
    font-size: 31px;
    row-gap: 6vw;
  }
`
const ListBox = styled.article`
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    gap: 90px;
  }
  @media (max-width: 730px) {
    row-gap: 12vw;
  }
`
const PageNationContainer = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    margin: 110px 0 130px;
  }
`

function Art() {
  const [isArticleList, setIsArticleList] = useState([]);
  const [isPageNation,setIsPageNation] = useState(1);
  useEffect(()=>{
    window.scrollTo(0,0)
    axios.get(process.env.REACT_APP_API_URL+"research/category/art")
    .then(res=>{
      setIsArticleList(res.data)
    })
    .catch(err=>{
      alert("데이터를 불러오지 못했습니다.")
    })
  },[])

  return (
    <Container>
      <TitleBox>
        <Title><IconSvg /><span>문화예술</span></Title>
        <div><p>다양한 사람들의 이야기를 아름답게 표현될 수 있도록</p><p>더 나은 세상을 만드는 퍼스트에듀</p></div>
        <div>퍼스트에듀는 취약계층 문화예술활동 지원을 통해 {"\n"}공연 및 예술 콘텐츠를 기획합니다.</div>
        <BannerContainer>
          <ImgTextContainer>
            <div>새로운 교육을 원한다면</div>
            <div></div>
            <div>퍼듀가 추천하는</div>
            <div>문화예술 교육콘텐츠!</div>
            <div>
              장애인식개선교육 및 필수 교육을 퍼듀에서{"\n"}
              색다른 방법으로 추천드립니다.
            </div>
            <Link to="https://pf.kakao.com/_xlkZiG/chat" target="_blank"><button>문화예술공연 신청하기 &gt;</button></Link>
          </ImgTextContainer>
          <Link to="https://pf.kakao.com/_xlkZiG/chat" target="_blank"><ButtonInImg>문화예술공연 신청하기 &gt;</ButtonInImg></Link>
        </BannerContainer>
      </TitleBox>
      <ListContainer>
        <ListTitle>문화예술 프로그램</ListTitle>
        <ListBox>
          {isArticleList.slice((isPageNation-1)*9,(isPageNation-1)*9+9).map(post=>(
            <Article category={post.category} post={post} />
          ))}
        </ListBox>
      </ListContainer>
      <PageNationContainer>
        <PageNation isArticleList={isArticleList} isPageNation={isPageNation} setIsPageNation={setIsPageNation} />
      </PageNationContainer>
    </Container>
  );
}
export default Art;