import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import Article from "./Article";
import UrlShare from "../../assets/share/urlShare.png";

import Parse from "../../components/Parse";

const Container = styled.main`
  width: 1299px;
  display: flex;
  flex-direction: column;
  row-gap: 108px;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 35px 0 0;
    row-gap: 0;
  }
` 
const Box = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 108px;
  align-items: center;
  @media (max-width: 1024px) {
    row-gap: 0;
    padding-bottom: 26.83vw;
  }
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 22px;
  &>h1{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 13px;
    font-size: 47px;
    letter-spacing: -.5px;
  }
  @media (max-width: 1024px) {
    padding: 0 5.85vw 11.7vw;
    row-gap: 2.93vw;
    &>h1{
      font-size: 7.8vw;
    }
  }
`
const CategoryAndDate = styled.div`
  display: flex;
  justify-content: center;
  font-size: 37px;
  font-weight: 400;
  letter-spacing: -.2px;
  &>:nth-child(1){
    padding-right: 14px;
  }
  &>:nth-child(2){
    padding-left: 14px;
    border-left: solid 1px black;
  }
  @media (max-width: 1024px) {
    font-size: 3.17vw;

  }
`
const ContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 108px;
  & iframe, & video, & img{
    max-width: 1299px;
  }
  @media (max-width: 1024px) {
    row-gap: 0;
    & iframe,& video,& img{
      max-width: 100%;
      margin-bottom: 5.85vw;
    }
  }
`
const ShareAndTag = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1vw;
  align-items: center;
`
const ShareBox = styled.div`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &>div{
    color: #504f4f;
    font-size: 22px;
    font-weight: 450;
    letter-spacing: -.5px;
  }
  &>button{
    /* height: 2.5vw;
    width: 2.5vw;
    background-image: url(${UrlShare});
    background-position: center;
    background-repeat: no-repeat;
    background-clip: border-box; */
  }
  @media (max-width: 1024px) {
    margin-top: 5.85vw;
    margin-bottom: 0;
    &>div{
      font-size: 22px;
    }
    &>button{
      /* height: 10vw;
      width: 10vw; */
    }
  }
`
const ModifyButton = styled.button`
  padding: 3px 7px;
  border-radius: 18px;
  color: white;
  font-size: 1vw;
  font-weight: 700;
  background-color: #f5b919;
  @media (max-width: 1024px) {
    font-size: 5vw;
  }
`
const TagContainer = styled.ul`
  padding-top: 2.44vw;
  display: flex;
  column-gap: 1vw;
  list-style: none;
`
const Tag = styled.li`
  padding: 1rem;
  border-radius: 50px;
  color: white;
  font-weight: 700;
  background-color: gray;
  white-space: pre;
  @media (max-width: 1024px) {
    height: 8.3vw;
    padding: 0 3.66vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.17vw;
    font-weight: 400;
  }
`
const ListBox = styled.article`
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    margin-top: 29.27vw;
    gap: 90px;
    justify-content: center;
  }
  @media (max-width: 730px) {
    row-gap: 12vw;
  }
`

function ArticleDetail(){
  const param = useParams().id;
  const [isArticle, setIsArticle] = useState({})
  const [isArticleList, setIsArticleList] = useState([])
  const [isCat, setIsCat] = useState()

  useEffect(()=>{
    window.scrollTo(0, 0)
    axios.get(process.env.REACT_APP_API_URL+"research/"+param)
    .then(res=>{
      setIsArticle({
        researchId: res.data.researchId,
        category: res.data.category,
        createdAt: res.data.createdAt.slice(0,4)+"."+res.data.createdAt.slice(5,7)+"."+res.data.createdAt.slice(8,10),
        title: res.data.title,
        subTitle: res.data.subResearchResponseDtos[0].subContent, //카테고리, 부제목
        videoUrl: res.data.videoUrl,
        imageUrl: res.data.imageUrl,
        content: res.data.content,
        subImageUrl1: res.data.subResearchResponseDtos[0].subImageUrl,
        subContent1: res.data.subResearchResponseDtos[1].subContent,
        subImageUrl2: res.data.subResearchResponseDtos[1].subImageUrl,
        subContent2: res.data.subResearchResponseDtos[1].subTitle,
        tag: res.data.subResearchResponseDtos[0].subTitle,
      })
      axios.get(process.env.REACT_APP_API_URL+"research/category/"+res.data.category)
      .then(res=>setIsArticleList(res.data))
      .catch(err=>console.log(err))
    })
    .catch(err=>console.log(err))
  },[param])

  useEffect(()=>{
    // axios.get("https://dog.ceo/api/breeds/image/random")
    axios.get("https://api.thecatapi.com/v1/images/search")
    .then(res=>setIsCat(res.data[0].url))
  },[])

  return(
    <Container>

      <Box>

        <TitleContainer>
          <CategoryAndDate>
            <span>
              {isArticle.category=="art"
              ? "문화예술"
              : isArticle.category=="edu"
              ? "교육"
              : isArticle.category=="branding"
              ? isArticle.subTitle
              : isArticle.category=="story"
              ? isArticle.subTitle
              : "카테고리"
              }
            </span>
            <span>
              {isArticle.createdAt}
            </span>
          </CategoryAndDate>
          <h1>{isArticle.title}</h1>
          <ShareBox>
            {/* <div>{window.location.href}</div> */}
            {/* <button
              onClick={()=>{
                navigator.clipboard.writeText(window.location.href)
                document.execCommand("copy",true,window.location.href)
                alert("링크가 복사되었습니다.")
              }}
            /> */}
          </ShareBox>
        </TitleContainer>

        <ContentContainer>
          {isArticle.videoUrl&&<video src={isArticle.videoUrl} controls={true} controlsList="nodownload" />}
          {isArticle.imageUrl&&<img src={isArticle.imageUrl || isCat} />}
          <Parse theme="snow" htmlString={isArticle.content} />
          {isArticle.subImageUrl1&&<img src={isArticle.subImageUrl1} />}
          {isArticle.subContent1&&<Parse theme="snow" htmlString={isArticle.subContent1} />}
          {isArticle.subImageUrl2&&<img src={isArticle.subImageUrl2} />}
          {isArticle.subContent2&&<Parse theme="snow" htmlString={isArticle.subContent2} />}
        </ContentContainer>

      </Box>

      <ShareAndTag>

        <ShareBox>
          {/* <div>{window.location.href}</div> */}
          {/* <button
            onClick={()=>{
              navigator.clipboard.writeText(window.location.href)
              document.execCommand("copy",true,window.location.href)
              alert("링크가 복사되었습니다.")
            }}
          /> */}
          {localStorage.getItem("theFirstEduLogin")===process.env.REACT_APP_LOGIN_HASH
          ? <Link to={"/write/"+param}><ModifyButton>게시글 수정</ModifyButton></Link>
          : null}
        </ShareBox>

        <TagContainer>{isArticle.tag? isArticle.tag.split("#").slice(1).map(tag=><Tag>#{tag}</Tag>) : <Tag>#더퍼스트에듀</Tag>}</TagContainer>

      </ShareAndTag>

      <ListBox>
        {isArticleList.slice(0,3).map(post=><Article category={post.category} post={post} />)}
      </ListBox>

    </Container>
  )
}
export default ArticleDetail;